import React, { useState } from "react";
import "./EnterpriseUserLogin.scss";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import FormInputs from "../../../Components/FormInputs/FormInputs";
import Buttons from "../../../Components/Buttons/Buttons";
import { errorToast, successToast } from "../../../services/ToastHelper";
import { getApi, postApi } from "../../../utils/apiService";
import {
  ENTERPRISE_LOGIN,
  GET_ALL_USER_LLM_DETAILS,
  LLM_USER_LOGIN,
} from "../../../utils/apiPath";
import { message } from "antd";

const initialValues = {
  email: "",
  password: "",
};

const EnterpriseUserLogin = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (name, value) => {
    setLogin({
      ...login,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateFields = () => {
    let errObj = { ...initialValues };

    if (!login.email) {
      errObj.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(login.email)) {
      errObj.email = "Enter Valid Email Id";
    } else {
      errObj.email = "";
    }

    if (!login.password) {
      errObj.password = "This field is required";
    } else if (login.password.length < 8) {
      errObj.password = "Password must be at least 8 characters";
    } else {
      errObj.password = "";
    }

    setErrors((prev) => ({ ...prev, ...errObj }));
    const data = Object.values(errObj).every((x) => x === "" || x === null);
    return data;
  };

  const handleLogin = async () => {
    if (validateFields()) {
      setIsLoading(true);
      const payload = {
        email: login.email,
        password: login.password,
      };
      const { status, data, message } = await postApi(LLM_USER_LOGIN, payload);
      if (status === 200) {
        setApiData(data.llmName);
        localStorage.setItem("userRole", "user");
        localStorage.setItem("accessToken", data.token);
        setIsLoading(false);
        successToast(message);
        // fetchAllLLMDetails();
        navigate("/enterprise/user/llms");
      } else {
        setIsLoading(false);
        errorToast(message);
      }
    }
  };

  // const fetchAllLLMDetails = async () => {
  //   setIsLoading(true);
  //   const { status, data, message } = await getApi(GET_ALL_USER_LLM_DETAILS);
  //   if (status === 200) {
  //     navigate("/enterprise/user/llms", {
  //       state: {
  //         storeData: data.assignedLLMList[0]?.llmId,
  //         llmData: data.assignedLLMList[0],
  //       },
  //     });

  //     setIsLoading(false);
  //   } else if (status == 401) {
  //     errorToast(message);
  //     setIsLoading(false);
  //   }
  // };

  const handleForgotPassword = () => {
    navigate("/enterprise/user/forgot");
  };

  const hanldeAdminLogin = () => {
    navigate("/");
  };

  return (
    <div className="ul">
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <Oval
              color="#86d3ff"
              height={50}
              width={50}
              radius="7"
              secondaryColor="#86d3ff"
            />
          </div>
        </div>
      )}
      <div className="ul_cont">
        <div className="ul_cont_section">
          <div className="ul_cont_section_header">
            <h3>User Sign In</h3>
          </div>
          <div className="ul_cont_section_inputs">
            <div className="ul_cont_section_inputs_data">
              <FormInputs
                title={"Enter Email"}
                type={"text"}
                placeholder={"Enter Your Email"}
                name="email"
                icon="icon1"
                value={login.email}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
            <div className="ul_cont_section_inputs_data">
              <FormInputs
                title={"Password"}
                type={"password"}
                placeholder={"********"}
                name="password"
                icon="icon2"
                value={login.password}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
              />
              {errors.password && (
                <span className="error">{errors.password}</span>
              )}
            </div>
          </div>
          <div className="ul_cont_section_forgot">
            <p onClick={hanldeAdminLogin}>Enterprise Admin Login</p>
            <p onClick={handleForgotPassword}>Forgot Password?</p>
          </div>
          <div className="ul_cont_section_buttons">
            <Buttons variant="primary" onClick={handleLogin}>
              Login
            </Buttons>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseUserLogin;
