import React, { useEffect, useState } from "react";
import "./UserLLM.scss";
import LLMIcon from "../../../Assets/Images/LLMIcon.png";
import { useNavigate } from "react-router-dom";
import { getApi } from "../../../utils/apiService";
import { GET_ALL_USER_LLM_DETAILS } from "../../../utils/apiPath";
import { Oval } from "react-loader-spinner";
import UserLayout from "../../../Components/UserLayout/UserLayout";

const UserLLM = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [storeData, setStoreData] = useState([]);

  const fetchAllLLMDetails = async () => {
    setIsLoading(true);
    const { status, data } = await getApi(GET_ALL_USER_LLM_DETAILS);
    if (status === 200) {
      setStoreData(data?.assignedLLMList);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllLLMDetails();
  }, []);

  const handleUserLLMDetails = (storeData) => {
    navigate(`/enterprise/user/chatbot/${storeData.llmId}`, {
      state: { storeData },
    });
  };




  return (
    <UserLayout>
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <Oval
              color="#86d3ff"
              height={50}
              width={50}
              radius="7"
              secondaryColor="#86d3ff"
            />
          </div>
        </div>
      )}
      <div className="llms">
        <div className="llms_header">
          <h2>Assigned AI Agent Details</h2>
        </div>
        <div className="llms_sec">
          {storeData.map((item) => (
            <div
              className="llms_sec_card"
              key={item.llmId}
              onClick={() => handleUserLLMDetails(item)}
            >
              <div className="llms_sec_card_top">
                <img src={LLMIcon} alt="LLM" />
              </div>
              <div className="llms_sec_card_bottom">
                <h3>
                  {item.llmName.length > 16
                    ? item.llmName.slice(0, 16) + "..."
                    : item.llmName}
                </h3>
                <span onClick={() => handleUserLLMDetails(item)}>
                  Mode Details
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </UserLayout>
  );
};

export default UserLLM;
