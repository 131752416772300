import React, { useEffect, useMemo, useState } from "react";
import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import "./LLMUserDetails.scss";
import Icons from "../../../Components/Table/Icon";
import Icon from "../../../Components/Table/Icon";
import CustomLayout from "../../../Components/CustomLayout/CustomLayout";
import { deleteApi, getApi, postApi } from "../../../utils/apiService";
import {
  ADD_LLM_USER,
  ADMAIN_STATUS,
  DELETE_LLM_USER,
  GET_ALL_LLM_USERS,
  PAYMENT_HISTORY,
} from "../../../utils/apiPath";
import { IconButton, Tooltip } from "@mui/material";
import { DeleteOutlined, VisibilityOutlined } from "@mui/icons-material";
import AdminConfirm from "../../../Components/AdminConfirm/AdminConfirm";
import { errorToast, successToast } from "../../../services/ToastHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { BackArrow } from "../../../Assets/icons";
import Buttons from "../../../Components/Buttons/Buttons";
import { Oval } from "react-loader-spinner";
import FormInputs from "../../../Components/FormInputs/FormInputs";
import { Popover, Switch } from "antd";

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const userInitialValues = {
  userName: "",
  userEmail: "",
  // userPassword: "",
};

const LLMUserDetails = () => {
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [userInputs, setUserInputs] = useState(userInitialValues);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [llmUserDetails, setLlmUserDetails] = useState([]);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [userPopUp, setUserPopUp] = useState(false);
  const navigate = useNavigate();

  const fetchAllLLMUsers = async (pageIndex, pageSize) => {
    setLoading(true);
    const { status, data } = await getApi(GET_ALL_LLM_USERS, {
      params: { pageIndex: pageIndex, pageSize: pageSize },
    });
    if (status === 200) {
      setLlmUserDetails(data?.llmUsersData);
      setPageCount(data?.totalPages);
    } else {
      setLlmUserDetails([]);
      setPageCount(0);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllLLMUsers(pageIndex, pageSize);
  }, [pageIndex, pageSize]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().padStart(4, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${day}/${month}/${year}`;
  };

  const handleLLMUserDelete = (userId) => {
    setUserIdToDelete(userId);
    setConfirmVisible(true);
  };

  const confirmActionHandler = async () => {
    setLoading(true);
    const { status, message } = await deleteApi(DELETE_LLM_USER, {
      params: { llmUserId: userIdToDelete },
    });
    if (status === 200) {
      successToast(message);
      fetchAllLLMUsers(pageIndex, pageSize);
      setConfirmVisible(false);
      setLoading(false);
    } else {
      errorToast(message);
      setConfirmVisible(false);
      setLoading(false);
    }
  };

  const handleLLMUserDetails = (userId) => {
    navigate("/all-llm-data", { state: { userId } });
  };

  const handleUserPopup = () => {
    setUserPopUp(true);
  };

  const handleUserChange = (name, value) => {
    setUserInputs({
      ...userInputs,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateUserFields = () => {
    let errObj = { ...userInitialValues };

    if (!userInputs.userName) {
      errObj.userName = "This field is required";
    } else {
      errObj.userName = "";
    }

    if (!userInputs.userEmail) {
      errObj.userEmail = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(userInputs.userEmail)) {
      errObj.userEmail = "Enter Valid Email Id";
    } else {
      errObj.userEmail = "";
    }

    // if (!userInputs.userPassword) {
    //   errObj.userPassword = "This field is required";
    // } else if (userInputs.userPassword.length < 8) {
    //   errObj.userPassword = "Password must be at least 8 characters";
    // } else {
    //   errObj.userPassword = "";
    // }

    setErrors((prev) => ({ ...prev, ...errObj }));
    const data = Object.values(errObj).every((x) => x === "" || x === null);
    return data;
  };

  const hanldeUserCreate = async () => {
    if (validateUserFields()) {
      setIsLoading(true);
      const payload = {
        // llmId: id,
        userName: userInputs.userName,
        userEmail: userInputs.userEmail,
        // userPassword: userInputs.userPassword,
      };

      const { status, message } = await postApi(ADD_LLM_USER, payload);
      if (status === 200) {
        successToast(message);
        setUserPopUp(false);
        setIsLoading(false);
        setUserInputs(userInitialValues);
      } else {
        errorToast(message);
        setUserPopUp(false);
        setIsLoading(false);
      }
    }
  };

  const hanldeUserCancel = () => {
    setUserPopUp(false);
    setUserInputs(userInitialValues);
  };

  const handleToggle = async (row) => {
    setIsLoading(true);
    try {
      const updatedValue = !row.original.isAdmin;
      const payload = {
        llmUserId: row.original.userId,
        isAdmin: updatedValue,
      };
      const { status, message } = await postApi(ADMAIN_STATUS, payload);
      if (status === 200) {
        successToast(message);
        setIsLoading(false);
        fetchAllLLMUsers(pageIndex, pageSize);
      } else {
        errorToast(message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const COLUMNS = useMemo(
    () => [
      {
        Header: "Sl No.",
        accessor: (row, index) => index + 1 + pageIndex * pageSize,
      },
      {
        Header: "AI Agent User Name",
        accessor: "userName",
        Cell: ({ value }) => (value ? capitalizeFirstLetter(value) : "N/A"),
      },
      {
        Header: "AI Agent User Email",
        accessor: "userEmail",
        Cell: ({ value }) => (value ? value : "N/A"),
      },
      {
        Header: "Created Date",
        accessor: "createdDate",
        Cell: ({ value }) => (value ? formatDate(value) : "N/A"),
      },
      {
        Header: "Total Assigned AI Agents",
        accessor: "TotalassignedLLMs",
        Cell: ({ value }) => value,
      },
      {
        Header: "isAdmin",
        accessor: "isAdmin",
        Cell: ({ row }) => (
          <Switch
            onChange={() => handleToggle(row)}
            checked={row.original.isAdmin}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
          />
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Tooltip title="View User AI Agent Details" placement="top">
              <strong
                style={{ cursor: "pointer" }}
                onClick={() => handleLLMUserDetails(row.original.userId)}
              >
                Details
              </strong>
            </Tooltip>
            <Tooltip title="Delete User" placement="top">
              <IconButton
                onClick={() => handleLLMUserDelete(row.original.userId)}
              >
                <DeleteOutlined sx={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    ],
    [pageIndex, pageSize]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    prepareRow,
  } = useTable(
    {
      columns: COLUMNS,
      data: llmUserDetails,
      manualPagination: true,
      pageCount,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  return (
    <CustomLayout>
      {isLoading && (
        <div className="loader-container">
          <Oval
            color="#86d3ff"
            height={50}
            width={50}
            radius="7"
            secondaryColor="#86d3ff"
          />
        </div>
      )}
      <div className="table">
        <div className="table_container">
          <div className="table_container_left">
            <h4>AI Agent Users Data</h4>
          </div>
          <div className="table_container_right">
            <Buttons variant="primary" onClick={handleUserPopup}>
              Add User
            </Buttons>
          </div>
        </div>
        <div className="table_sec">
          <div className="table_sec_cont">
            <div
              className="table_sec_cont_details"
              style={{ overflow: "hidden" }}
            >
              <table {...getTableProps()}>
                <thead>
                  {headerGroups?.map((headerGroup, index) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={`header-${index}`}
                    >
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          key={`column-${index}`}
                          scope="col"
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {loading ? (
                    <tr>
                      <td
                        colSpan={COLUMNS.length}
                        className="loading-container"
                      >
                        <div className="loading-indicator">Loading...</div>
                      </td>
                    </tr>
                  ) : llmUserDetails?.length === 0 ? (
                    <tr>
                      <td colSpan={COLUMNS.length} className="no-data">
                        No Users
                      </td>
                    </tr>
                  ) : (
                    page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={`row-${index}`}>
                          {row.cells.map((cell, cellIndex) => (
                            <td
                              {...cell.getCellProps()}
                              key={`cell-${cellIndex}`}
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="table_page">
          <div className="table_page_section">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setPageIndex(0);
              }}
            >
              {[5, 10].map((size) => (
                <option key={size} value={size}>
                  Show {size}
                </option>
              ))}
            </select>
            <span>
              Page{" "}
              <span>
                {pageIndex + 1} of {pageCount}
              </span>
            </span>
          </div>
          <ul>
            <li>
              <button
                style={{
                  cursor: pageIndex !== 0 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(0)}
                disabled={pageIndex === 0}
              >
                <Icons icon="heroicons:chevron-double-left-solid" />
              </button>
            </li>
            <li>
              <button
                style={{
                  cursor: pageIndex !== 0 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(pageIndex - 1)}
                disabled={pageIndex === 0}
              >
                Prev
              </button>
            </li>

            {pageOptions?.map((page) => (
              <li key={page}>
                <button
                  style={{
                    backgroundColor: page === pageIndex ? "#2D3748" : "#EDF2F7",
                    color: page === pageIndex ? "#fff" : "#2D3748",
                  }}
                  onClick={() => setPageIndex(page)}
                >
                  {page + 1}
                </button>
              </li>
            ))}
            <li>
              <button
                style={{
                  cursor: pageIndex !== pageCount - 1 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(pageIndex + 1)}
                disabled={pageIndex === pageCount - 1}
              >
                Next
              </button>
            </li>
            <li>
              <button
                style={{
                  cursor: pageIndex !== pageCount - 1 ? "pointer" : "default",
                }}
                onClick={() => setPageIndex(pageCount - 1)}
                disabled={pageIndex === pageCount - 1}
              >
                <Icon icon="heroicons:chevron-double-right-solid" />
              </button>
            </li>
          </ul>
        </div>
      </div>
      {confirmVisible && (
        <AdminConfirm
          title={"Are you sure you want to Delete this User"}
          onCancel={() => setConfirmVisible(false)}
          onConfirm={confirmActionHandler}
        />
      )}
      {userPopUp && (
        <div className="user">
          <div className="user_popup">
            <div className="user_popup_inner">
              <h2>Add AI Agent User</h2>
            </div>
            <div className="user_popup_body">
              <div className="user_popup_body_inputs">
                <FormInputs
                  title={"User Name"}
                  placeholder={"Enter User Name"}
                  type={"text"}
                  value={userInputs.userName}
                  name="userName"
                  onChange={handleUserChange}
                />
                {errors.userName && (
                  <span className="error">{errors.userName}</span>
                )}
              </div>
              <div className="user_popup_body_inputs">
                <FormInputs
                  title={"User Email"}
                  placeholder={"Enter User Email"}
                  type={"text"}
                  value={userInputs.userEmail}
                  name="userEmail"
                  onChange={handleUserChange}
                />
                {errors.userEmail && (
                  <span className="error">{errors.userEmail}</span>
                )}
              </div>
              {/* <div className="user_popup_body_inputs">
                <FormInputs
                  title={"User Password"}
                  placeholder={"Enter User Password"}
                  type={"password"}
                  value={userInputs.userPassword}
                  name="userPassword"
                  onChange={handleUserChange}
                />
                {errors.userPassword && (
                  <span className="error">{errors.userPassword}</span>
                )}
              </div> */}
            </div>
            <div className="user_popup_buttons">
              <button onClick={hanldeUserCancel}>Cancel</button>
              <button onClick={hanldeUserCreate}>Create</button>
            </div>
          </div>
        </div>
      )}
    </CustomLayout>
  );
};

export default LLMUserDetails;
